<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="edit" name="plantCd" v-model="searchParam.plantCd"/>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 수강자 -->
          <c-field
            :editable="false"
            :data="searchParam"
            type="user"
            label="LBL0002949"
            name="attendeesId"
            v-model="searchParam.attendeesId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="LBL0002829"
            v-model="searchParam.educationPeriod"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <!-- 동영상 교육 목록 -->
    <c-table
      ref="table"
      title="LBL0002950"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      checkDisableColumn="disable"
      :checkClickFlag="false"
      rowKey="eduEducationId"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'vodPlayTime'">
          <q-chip
            :color="getPlayTimeColor(props.row.vodTotalTime)"
            outline square
            :clickable="false"
            text-color="white">
            {{getPlayTime(props.row)}}
          </q-chip>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <!-- <c-dialog :param="popupOptions"></c-dialog> -->
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-result-vod",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            // 사업장
            label: "LBLPLANT",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationName",
            field: "educationName",
            // 교육명
            label: "LBL0002929",
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            // 교육과정
            label: "LBL0002930",
            align: "left",
            sortable: true,
          },
          {
            name: "educationDate",
            field: "educationDate",
            // 교육일
            label: "LBL0002931",
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            // 교육종류1
            label: "LBL0002933",
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            // 교육종류2
            label: "LBL0002934",
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            // 교육구분
            label: "LBL0002935",
            align: "center",
            sortable: true,
          },
          {
            name: "vodPlayTime",
            field: "vodPlayTime",
            // 동영상 시청시간
            label: "LBL0002923",
            align: "center",
            style: 'width:180px',
            sortable: true,
            type: 'custom'
          },
          // {
          //   name: "vodPlayTime",
          //   field: "vodPlayTime",
          //   label: "동영상 시청시간",
          //   align: "center",
          //   style: 'width:120px',
          //   sortable: true,
          //   type: 'custom'
          // },
          {
            name: "checkCompleteFlagName",
            field: "checkCompleteFlagName",
            // 이수구분
            label: "LBL0002951",
            align: "center",
            sortable: true,
          },
          {
            name: "evaluationDate",
            field: "evaluationDate",
            // 이수일
            label: "LBL0002868",
            align: "center",
            style: 'width:100px',
            sortable: true,
          },
          {
            name: "eduQuestionFlag",
            field: "eduQuestionFlag",
            label: "문제풀이",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        documentStatusCd: null,
        startYmd: '',
        endYmd: '',
        educationPeriod: [],
        eduCourseId: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        attendeesId: '',
      },
      editable: true,
      listUrl: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isCourse() {
      return Boolean(this.searchParam.eduCourseId)
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.edu.result.vods.url;
      this.searchParam.attendeesId = this.$store.getters.user.userId;
      this.searchParam.educationPeriod = [this.$comm.getFirstDayThisYear(), this.$comm.getLastDayThisYear()];
      this.getList();
    },
    getPlayTime(_row) {
      if (_row.vodTotalTime === null) {
        return '시청 전';
      } else {
        let m1 = parseInt(_row.vodPlayTime / 60);
        let s1 = parseInt(_row.vodPlayTime % 60);
        let m2 = parseInt(_row.vodTotalTime / 60);
        let s2 = parseInt(_row.vodTotalTime % 60);
        return m1 + '분 ' + s1 + '초' + ' / ' + m2 + '분 ' + s2 + '초'
      }
    },
    getPlayTimeColor(_min) {
      if (_min === null) {
        return 'grey-6';
      } else {
        return 'blue'
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row) {
      const newWindow = 
        window.open(
          `/sop/edu/result/educationResultVodDetail?eduEducationId=${row.eduEducationId}`,
          'vodstream',
          `width=1700, height=900, menubar=no, status=no, toolbar=no`
        );
    },
  },
};
</script>
